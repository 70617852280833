/*body {*/
  /*!*margin: 0;*!*/
  /*!*padding: 0;*!*/
  /*!*display: block;*!*/
  /*!*margin-left: auto;*!*/
  /*!*margin-right: auto;*!*/
  /*!*text-align: center;*!*/
  /*!*width: 40%;*!*/
  /*!*font-family: sans-serif;*!*/
  /*margin: auto;*/
    /*width: 40%;*/
    /*border: 3px solid #73AD21;*/
    /*padding: 10px;*/
/*}*/

body {
  background-color: lightgray;
}


#root {
  /*margin: 0;*/
  /*padding: 0;*/
  /*display: block;*/
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  text-align: center;
  /*width: 40%;*/
  /*font-family: sans-serif;*/
  margin: 30px auto 30px auto;
  width: 60%;
  min-width: 400px;
  max-width: 600px;
  /*min-height: 400px;*/
  background-color: white;
  border: 2px solid gray;
  padding: 20px;
}

.app {
}

.measurement-info-form {
  width: 70%;
  min-width: 300px;
  max-width: 450px;
  margin: auto;
  text-align: left;
  overflow: hidden;
}

.trial-plot {
  width: 100%;
}

.abort-button {
  text-align: left;
}

.study-info-card,
.participant-info-card,
.measurement-settings-card,
.login-card,
.register-card,
.landing-card,
.account-settings-card
{
  margin: 2em 0 0 0;
}

.measurement-info-form-buttons {
  margin: 1em 0 0 0;
}

.measurement-info-form-buttons .back-button{
  float: left;
}

.measurement-info-form-buttons .start-button{
  width: 50%;
  float: right;
}

.study-info-card .card-header,
.participant-info-card .card-header,
.measurement-settings-card .card-header,
.login-card .card-header,
.register-card .card-header,
.landing-card .card-header,
.account-settings-card .card-header
{
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.landing-card .card-header,
.account-settings-card .card-header
{
  cursor: default;
}

.tooltip-element {
  display: inline-block;
  margin: 0 0 0 0.75em;
  width: 1.5em;
  height: 1.5em;
  /*line-height: 26px;*/
  font-size: 0.8em;
  border-radius: 50%;
  background-color: lavender;
  text-align: center;
}

.tooltip-element:before {
  content: '?';
}

.input-label-required:after {
  content: '*';
  color: red;
}


.btn-circle {
  font-size: xx-large;
  width: 75px;
  height: 75px;
  line-height: 75px; /* adjust line height to align vertically*/
  padding:0;
  margin: 0 0.25em 0 0.25em;
  border-radius: 50%;
}

.landing {
}

.account-settings-card {
    text-align: left;
}
